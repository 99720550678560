<style lang="scss">
    i.v-icon.notranslate.mdi.mdi-clock-time-four-outline.theme--light {
        margin-top: -14px !important;
    }
    i.v-icon.notranslate.mdi.mdi-calendar.theme--light {
        margin-top: -11px !important;
    }

</style>

<style lang="scss">
    /* eslint-disable */
    .v-text-field > .v-input__control > .v-input__slot:before,
    .v-text-field > .v-input__control > .v-input__slot:after {
        width: 0 !important;
    }
    .text-area textarea{
        border: 1px solid rgba(0, 0, 0, 0.42);
        border-radius: 5px;
        padding: 0 12px;
    }
    .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
        border-color: rgba(0, 0, 0, 0.42);
        border-width: 0;
    }
    .p10{
        padding: 10px ;
    }
    .content-list{
        overflow-y: auto;
        min-height: auto;
        max-height: 85px;
    }
    .paddinglist{
        padding: 15px 5px 10px 5px !important;
    }
    .positionmostrar{
        position: absolute;
        z-index: 4;
        top: -17px;
        left: -5px;
    }

    /* -------------------- */
    .mb15{
        margin-bottom: 15px;
    }
    .mr5{
        margin-right: 5px;
    }
    .main{
        padding: 55px 10px 0px!important;
    }
        .leftBlock {
            max-width: 250px;
            transition: all 0.5s ease;
            padding-right: 15px;

            .listado_solicitudes {
                min-height: auto;
                // max-height: 600px;
                max-height: calc(100vh - 96px);
                overflow: auto;
                /* border: 1px solid #666666; */
                // box-shadow: 9px 8px 27px -13px rgb(116 116 116 / 68%);
                // background-color: #f1f1f1;
                /* width: 220px; */
                padding: 0;
                /* margin: 1rem 1rem 0 0; */
                border-radius: 5px;
                /* min-height: 100vh; */
            }
        }
        .rightBlock{
            width: 100%;
        }
        .main_solicitudes{
            padding: 50px 41px 0px 41px;
        }
</style>

<template>
    <!--eslint-disable-->
    <div class="main_solicitudes">
        <div class="text-left mb15">
            <p class="fs20 bold mb-0">{{$t('solicitudes.coo')}}</p>
        </div>
        <div class="text-left mb15">
            <!-- positionmostrar -->
            <a @click="ocultarbarra" class="">
                <v-icon class="mr5">{{leftBar ? 'mdi-arrow-left-drop-circle-outline' : 'mdi-arrow-right-drop-circle-outline'}}</v-icon> 
                <span class="fs14 gray_color normal">{{leftBar ? $t('solicitudes.ocultar') : $t('solicitudes.mostrar') }}</span>
            </a>
        </div>
        <div class="d-flex">
            <div class="leftBlock" v-show="leftBar">
                <p class="text-left mb15 fs16">
                    {{$t('solicitudes.total_soli')}}: <span class="bold c-azul">{{ solicitudes_filtered.length }}</span>
                </p>
                <div class="my-3">
                    <v-select
                        v-model="cliente"
                        :items="CLIENTES_DB"
                        item-text="nombre"
                        item-value="id"
                        :label="$t('solicitudes.cliente')"
                        dense
                        outlined
                        return-object
                        @change="filterClientesData"
                    ></v-select>
                    
                </div>

                <div class="listado_solicitudes px-2">
                    <v-card
                        class="mx-auto mb15 p10"
                        max-width="344"
                        elevation="3"
                        outlined
                        v-for="(item, index) in solicitudes_filtered"
                        :key="index"
                        @click="selectSolicitud(item)"
                        style="cursor: pointer;"
                    >
                        <div class="align-items-center d-flex justify-content-end" >
                            <div class="align-items-center d-flex fs12">
                                <p class="mb-0 c-azul normal">{{item.id}}</p>
                                <v-icon color="#888888">mdi-chevron-right</v-icon>
                            </div>
                        </div>
                        <div>
                            <span class="d-flex align-items-center">
                                <svg  style="width: 16px !important; height: 16px !important;">
                                    <use xlink:href='/img/icons/icons.svg#local'></use>
                                </svg>
                                <p class="text-left mb-0 fs12 pl-2">
                                    {{item.local.nombre_local}}
                                </p>
                            </span>
                            <span class="d-flex align-items-center">
                                <svg  style="width: 20px !important; height: 20px !important;">
                                    <use xlink:href='/img/icons/icons.svg#pin'></use>
                                </svg>
                                <p class="text-left  pl-2 mb-0 fs12">
                                    {{ item.local.direccion_completa }}
                                </p>
                            </span>
                        </div>
                    </v-card>
                </div>
            </div>
            <div class="rightBlock">
                <v-sheet height="64">
                    <v-toolbar
                        flat
                    >
                        <v-btn
                            class="c-btn"
                            color="#007AFF"
                            outlined
                            @click="setToday"
                        >
                            {{$t('solicitudes.hoy')}}
                        </v-btn>

                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            color="primary"
                            @click="prev"
                        >
                            <v-icon>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>

                        <v-toolbar-title v-if="$refs.calendar">
                            {{ getMes($refs.calendar.title) }}
                        </v-toolbar-title>

                        <v-btn
                            icon
                            color="primary"
                            @click="next"
                        >
                            <v-icon>
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-menu
                            bottom
                            right
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="c-btn"
                                    color="#007AFF"
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <span>{{ typeToLabel[type] }}</span>
                                    <v-icon right>
                                        mdi-menu-down
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="type = 'month'">
                                    <v-list-item-title class="paddinglist">{{$t('solicitudes.mes')}}</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'week'">
                                    <v-list-item-title class="paddinglist">{{$t('solicitudes.semana')}}</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'day'">
                                    <v-list-item-title class="paddinglist">{{$t('solicitudes.dia')}}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                    <!-- @change="updateRange" -->
                    <v-calendar
                        :locale="lang"
                        ref="calendar"
                        v-model="focus"
                        color="primary"
                        :events="EVENTOS_DB"
                        :event-color="getEventColor"
                        :type="type"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        @change="getEventos"
                    ></v-calendar>
                    <v-menu
                        v-model="selectedOpen"
                        :close-on-content-click="false"
                        :activator="selectedElement"
                        offset-x
                    >
                        <v-card
                            color="grey lighten-4"
                            min-width="350px"
                            flat
                        >
                            <v-toolbar
                                :color="selectedEvent.color"
                                dark
                            >
                                <v-toolbar-title>{{selectedEvent.name}} {{selectedEvent.solicitud_id}}</v-toolbar-title>

                                <v-spacer></v-spacer>

                                <v-btn icon @click="selectedOpen = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-card-text class="px-3 bg--normal__white">
                                <div>
                                    <div class="text-right">
                                        <p class="mb-0"><v-icon small>mdi-calendar-blank-outline</v-icon> {{ selectedEvent.date }}</p>
                                        <p><v-icon small>mdi-timer-sand</v-icon> {{ selectedEvent.StartTime }} / <v-icon small>mdi-timer-sand-complete</v-icon> {{ selectedEvent.EndTime }}</p>
                                    </div>
                                    <div>
                                        <p class="mb-0 text-left bold">{{$t('solicitudes.Asunto')}}: <span class="light">{{ selectedEvent.Subject }}</span></p>
                                        <p class="mb-0 text-left bold">{{$t('solicitudes.direccion')}}:</p>
                                        <p class="mb-0 text-left bold">{{$t('solicitudes.Descripcion')}}: <span class="light">{{ selectedEvent.Description }}</span></p>
                                    </div>
                                </div>
                            </v-card-text>
                            <v-card-actions class="justify-content-center bg--normal__white">
                                <v-btn
                                    class="c-btn bg--normal__white"
                                    color="#007AFF"
                                    outlined
                                    @click="btnEliminarSolicitud(selectedEvent)"
                                >
                                    {{$t('solicitudes.Eliminar')}}
                                </v-btn>
                                <v-btn
                                    class="c-btn c-btn--blue bold"
                                    outlined
                                    @click="editarSolitud(selectedEvent)"
                                >
                                    {{$t('solicitudes.Editar')}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-sheet>
                
            </div>
        </div>
        <!-- modal -->
        <div class="text-center">
            <v-dialog
                v-model="showModalEvento"
                persistent
                width="600"
            >
                <v-card>
                    <v-card-title class="pb-0 text-h5 c-black">
                        <p class="mb-2">{{(banderaEditarSolicitud == true)? $t('solicitudes.EditarEvento') : $t('solicitudes.crear_evento') }} <span class="c-azul">{{solicitud_id}}</span></p>
                    </v-card-title>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="Subject"
                                    dense
                                    outlined
                                    clearable
                                    :label="$t('solicitudes.asunto')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-menu
                                    class="i-calendar"
                                    ref="showDate"
                                    v-model="showDate"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    @input="filterMuestreadorData('date', 'DESDE CALENDARIO')"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            class="pt-0 mt-0"
                                            v-model="date"
                                            :label="$t('solicitudes.fechaasignado')"
                                            prepend-icon="mdi-calendar"
                                            dense
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        :locale="lang"
                                        v-if="showDate"
                                        v-model="date"
                                        no-title
                                        scrollable
                                        @input="$refs.showDate.save(date)"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <!-- startTime -->
                            <v-col
                                cols="4"
                            >
                                <v-select
                                    v-model="StartTime"
                                    :label="$t('solicitudes.Horainicio')"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    :items="catStartTime"
                                    dense
                                    outlined
                                    @input="calcularhora(StartTime)"
                                    @change="filterMuestreadorData('StartTime', 'Desde Hora Inicio')"
                                ></v-select>
                            </v-col>
                            <!-- endTime -->
                            <v-col
                                cols="4"
                            >
                                <v-select
                                    v-model="EndTime"
                                    :label="$t('solicitudes.Horatermino')"
                                    prepend-icon="mdi-clock-time-four-outline"
                                    :items="catEndTime"
                                    dense
                                    outlined
                                    @input="calcularhora(EndTime)"
                                    @change="filterMuestreadorData('EndTime', 'Desde Hora Fin')"
                                ></v-select>
                            </v-col>
                            <!-- lista -->
                            <v-col class="text-left" cols="12">
                                <label>{{$t('solicitudes.resumen_muestra')}}</label>
                                <v-list class="content-list">
                                    <v-list-item-group v-model="model">
                                        <v-list-item
                                            v-for="(item, i) in items"
                                            :key="i"
                                        >
                                            <v-list-item-title v-if="item.count > 0" class="p-2 c-azul2">
                                                {{item.text}}: {{item.count}}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                            <!-- fin lista -->
                            <v-col cols="12">
                                <!-- {{muestreador}} -->
                                <v-select
                                    v-model="muestreador"
                                    :items="muestreadores_filtered"
                                    item-text="fullname"
                                    item-value="user_id"
                                    :label="$t('solicitudes.muestreador')"
                                    dense
                                    outlined
                                    return-object
                                ></v-select>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    v-model="direccion"
                                    :label="$t('solicitudes.direccion')"
                                    dense
                                    outlined
                                    disabled
                                ></v-text-field>
                            </v-col>

                            <v-col class="text-left" cols="12">
                                <v-textarea
                                    class="text-area"
                                    :label="$t('solicitudes.obvs')"
                                    :placeholder="$t('solicitudes.Ingresarcomentarionomayora200caracteres')"
                                    v-model="Description"
                                    auto-grow
                                    rows="3"
                                    :rules="rules"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="px-6">
                        <v-spacer></v-spacer>
                        <v-btn
                            class="c-btn"
                            color="#007AFF"
                            outlined
                            @click="closeModal"
                        >
                            {{ $t('ensayos.cancelar') }}
                        </v-btn>

                        <v-btn
                            class="ml-5 c-btn c-btn--blue bold"
                            outlined
                            @click="saveEvent"
                        >
                        {{ $t('ensayos.guardar') }}
                        </v-btn>
                        
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
/*eslint-disable*/
import i18n from '../../plugins/il8n';
import Service from '../../services/apis';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';

    export default {
        data: () => ({
            leftBar: true,
            banderaEditarSolicitud: false,

            // ----------------------------- EVENTOS del CALENDARIO:
            EVENTOS_DB          : [],
            /*evento_tmp          : {
                deleted_at      : null,
                nom_muestreador : "Alan Sampler 1",

                // color
                // name
                // end
                // start
                // timed

                solicitud_id    : 860,
                Subject         : "A1",

                // date
                StartTime       : "2022-09-01T07:00:00.000Z",
                EndTime         : "2022-09-01T07:30:00.000Z",
                
                Muestreador     : 136,
                Description     : "B1",
                id              : 57,
            },*/

            SOLICITUDES_DB      : [],
            solicitudes_filtered: [],
            // solicitud           : null,

            CLIENTES_DB         : [],
            cliente             : null,

            MUESTREADORES_DB    : [],
            muestreadores_filtered : [],


                // ----------------------------- FORMULARIO:
                showModalEvento : false,
                // POR UTILIZAR, PENDIENTE:
                formEvento      : {
                    solicitud_id: null,

                    Subject     : null,
                    date        : null,
                    StartTime   : null,
                    EndTime     : null,
                    Muestreador : null,
                    direccion   : null,
                    Description : null,
                    nom_muestreador: null,
                    // Location
                    id          : null,
                },



            catEndTime:['07:00','07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00',
                '11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30',
                '16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00',
                '20:30','21:00','21:30','22:00','22:30','23:00','23:30',],
            catStartTime: [
                '07:00','07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00',
                '11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30',
                '16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00',
                '20:30','21:00','21:30','22:00','22:30','23:00','23:30',
            ],

            // ----------------------------- FORMULARIO:
            // REMPLAZAR ESTO POR LA VARIABLE SOLICITUD:
            solicitud_id        : null,
                Subject         : '',
                model           : null,// interacion de for:

                showDate        : null,
                date            : null,

                showStartTime   : false,
                StartTime       : null,

                showEndTime     : false,
                EndTime         : null,

                muestreador     : null,
                location        : '',
                direccion       : '',
                Description     : '',


            
            items: [{
                text: 'Alimentos',
                count: 0,
            },{
                text: 'Ambientes',
                count: 0,
            },{
                text: 'Agua',
                count: 0,
            },{
                text: 'Manipuladores',
                count: 0,
            },{
                text: 'Superficies',
                count: 0,
            }],
            
            // ----------------------------- NO TOCAR - SON DEL CALENDARIO:
            type: 'month',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
                '4day': '4 Days',
            },
            selectedEvent: {},
            selectedElement: null,
            
            events: [],
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],


            focus   : '',
            selectedOpen: false,
            // ----------------------


            rules: [v => v.length <= 200 || i18n.t('solicitudes.Max200characters')],

            // names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
            cat_events:[
                {color:'blue', name:'Meeting'},
                {color:'red', name:'Holiday'},

                {color:'indigo', name:'PTO'},
                {color:'deep-purple', name:'Travel'},

                {color:'cyan', name:'Event'},
                {color:'green', name:'Birthday'},

                {color:'orange', name:'Conference'},
                {color:'grey darken-1', name:'Party'},
            ],

        }),
        mounted () {
            this.$refs.calendar.checkChange()
            // console.clear()
            this.getData()
        },
        methods: {
            async getData(){
                // console.log({'ASDASDS' : this.$ls.storage.token})
                const response = await Service.apiToken('POST', 'Solicitud/getData', this.$ls.storage.token, {});
                // const response = await Service.apiTokenMultipart('POST', 'Solicitud/getData', this.$ls.storage.token, data);
                console.log({response})


                // ----------------------------------- ----------------------------------- CLIENTES:
                this.CLIENTES_DB = response.data.Clientes
                // console.log({'this.CLIENTES_DB':this.CLIENTES_DB});

                // Cuando solo trae 1 cliente, seleccionarlo por default.
                if( this.CLIENTES_DB.length == 1 ){
                    this.cliente = this.CLIENTES_DB[0]
                }


                // ----------------------------------- ----------------------------------- MUESTREADORES:
                this.MUESTREADORES_DB = this.setColorToDataSet(response.data.Muestreadores)
                // console.log({'this.MUESTREADORES_DB':this.MUESTREADORES_DB});


                // ----------------------------------- ----------------------------------- SOLICITUDES:
                this.SOLICITUDES_DB = response.data.Solicitudes.map( item => {
                    let direccion = JSON.parse(item.local.direccion);
                    item.local.direccion = direccion
                    item.local.direccion_completa = this.formatDireccionCompleta(direccion)
                    return item
                })
                // console.log({'this.SOLICITUDES_DB':this.SOLICITUDES_DB});

                // this.solicitudes_filtered = this.SOLICITUDES_DB.filter( item => item.jqcalendar == null )
                this.solicitudes_filtered = this.SOLICITUDES_DB//.filter( item => item.jqcalendar == null )
                console.log({'this.solicitudes_filtered':this.solicitudes_filtered});
            },
            async getEventos(){
                const response = await Service.apiToken('POST', 'Solicitud/getEventos', this.$ls.storage.token, {});
                console.log({response})
                this.EVENTOS_DB = this.convertToEventsDataSet( response.data.Eventos )
            },
            setColorToDataSet(data){
                return data.map(item => {
                    item.color = this.colors[ this.rnd(0, this.colors.length - 1) ]
                    return item
                })
            },
            convertToEventsDataSet(data){
                console.log({data})
                // if( Object.keys(data).length === 0 ){
                //     return []
                // }

                console.log(data[0]);

                // ENTRADA:
                    // Description: "Prueba"
                    // EndTime: "2022-08-24T08:00:00.000Z"
                    // Muestreador: 136
                    // StartTime: "2022-08-24T07:00:00.000Z"
                    // Subject: "1"
                    // id: 17
                    // nom_muestreador: "Alan Sampler 1"

                // SALIDA:
                    // color: "orange"
                    // end: Sun Aug 21 2022 14:00:00 GMT-0500 (hora de verano central)
                    // name: "Conference"
                    // start: Sat Aug 20 2022 13:15:00 GMT-0500 (hora de verano central)
                    // timed: false

                const response = [];
                data[0].map((item)=>{
                    // Removemos '.000Z':
                    item.EndTime   = item.EndTime.replace(".000Z", "")
                    item.StartTime = item.StartTime.replace(".000Z", "")

                    // Partimos el string y tomamos la hora final, inicio y la fecha: 
                    const end_time   = item.EndTime.split("T")[1];
                    const start_time = item.StartTime.split("T")[1];

                    const date = item.StartTime.split("T")[0];


                    const color_by_muestreador = 'grey darken-1'/*this.MUESTREADORES_DB.filter(item2 => {
                        return item.Muestreador == item2.user_id
                    })[0].color*/

                    response.push({
                        color: color_by_muestreador,
                        name: item.nom_muestreador,
                        end: new Date(item.EndTime),
                        start: new Date(item.StartTime),
                        timed: true,

                        // additionalData
                        solicitud_id: item.solicitud_id,
                        Subject: item.Subject,

                        date : date,
                        StartTime: start_time,
                        EndTime: end_time,

                        Muestreador: item.Muestreador,
                        Description: item.Description,
                        Location: item.Location,
                        id: item.id,
                        preot: item.pre_ot
                    })
                })

                console.log({response})
                return response
            },
            formatDireccionCompleta(item){
                return `${item.direccion}, ${item.comuna}, ${item.ciudad}, ${item.codigo_postal}`;
            },
            // ---------------------- CREAR NUEVO EVENTO:
            selectSolicitud(item){
                console.log({item})

                this.solicitud_id = item.id
                console.log({'this.solicitud_id' : this.solicitud_id})


                // console.log({'this.solicitudes_filtered':this.solicitudes_filtered})


                this.cliente = this.CLIENTES_DB.filter(item2 => item2.id == item.cliente_id)[0]
                console.log({'this.cliente':this.cliente})

                this.direccion = this.solicitudes_filtered.filter(item2 => item2.id == this.solicitud_id)[0].local.direccion_completa
                // console.log({'this.direccion':this.direccion})

                console.log({'this.MUESTREADORES_DB':this.MUESTREADORES_DB})
                // console.log({'this.muestreadores_filtered':this.muestreadores_filtered})
                // console.log({'this.EVENTOS_DB':this.EVENTOS_DB})


                // console.log({item})
                // console.log({'this.CLIENTES_DB':this.CLIENTES_DB})
                
                // this.cliente_id = item.cliente_id

                // this.muestreadores_filtered = this.MUESTREADORES_DB.filter(item => !muestreador_id_array.includes(item.user_id) )
                this.muestreadores_filtered = this.MUESTREADORES_DB;
                // console.log(this.MUESTREADORES_DB);
                // console.log(this.muestreadores_filtered);
                
                // console.log({'this.solicitud_id':this.solicitud_id})


                // this.direccion = item.local.direccion_completa
                this.obtenerConteoMuestras(item.muestras_preots);
                this.banderaEditarSolicitud = false;
                this.showModalEvento = true
            },
            getDireccionSolicitud(){
                return solicitudes_filtered.filter(item => item.id == this.solicitud_id)[0].local.direccion_completa
            },
            filterMuestreadorData(from_txt, txt){
                if( from_txt == 'StartTime' ){
                    this.EndTime = null
                }
                // console.log( txt )
                // console.log({'this.showDate':this.showDate})
                // console.log({'this.date':this.date})
                // console.log({'this.StartTime':this.StartTime})
                // console.log({'this.EndTime':this.EndTime})
                if( this.showDate == false && this.date != null  &&  this.StartTime != null && this.EndTime != null ){
                    console.log('Llamar Funcion:')

                    const eventsInSameDay = this.EVENTOS_DB.filter(item => item.date == this.date)
                    // console.log({eventsInSameDay})

                    const eventsBetweenHours = eventsInSameDay.filter(item => {
                        const item_EndTime   = parseInt(item.EndTime.replace(':',''))
                        const item_StartTime = parseInt(item.StartTime.replace(':',''))

                        const this_EndTime   = parseInt(this.EndTime.replace(':',''))
                        const this_StartTime = parseInt(this.StartTime.replace(':',''))

                        /*if( item_EndTime <= this_StartTime || item_StartTime >= this_EndTime ){
                            
                            item.val = 3
                            if( item_EndTime <= this_StartTime ){
                                console.log(item_EndTime + '<=' + this_StartTime)
                                item.val = 2
                            }
                            if( item_StartTime >= this_EndTime ){
                                console.log(item_StartTime + '>=' + this_EndTime)
                                item.val = 1
                            }
                        }*/

                        // horaFinal <= a la hora seleccionada
                        // horaInicio > a la hora seleccionada
                        // return  item_EndTime <= this_StartTime || item_StartTime >= this_EndTime
                        return  item_EndTime > this_StartTime && item_StartTime < this_EndTime
                    })
                    // console.log({eventsBetweenHours})

                    console.log('eventsToShow')
                    // const eventsToShow = 
                    eventsBetweenHours.map(item => {
                        console.log(item.StartTime+' - '+item.EndTime+' - '+item.Muestreador)
                        
                    })
                    // console.log(eventsToShow)


                    // Muestreadores que debe excluir de el listado de muestreadores
                    const muestreador_id_array = this.unique(eventsBetweenHours.map(item => item.Muestreador))
                    console.log({muestreador_id_array})


                    
                    this.muestreadores_filtered = this.MUESTREADORES_DB.filter(item => !muestreador_id_array.includes(item.user_id) )
                    // console.log(this.MUESTREADORES_DB);
                    // console.log(this.muestreadores_filtered);

                }
                // this.muestreadores_filtered = this.MUESTREADORES_DB
            },
            unique(val){
                return val.filter(function(value, index, self) {
                    return self.indexOf(value) === index;
                })
            },
            // unique(value, index, self) {
            //     return self.indexOf(value) === index;
            // },
            async saveEvent(){
                // const tmp = {
                //     'this.solicitud_id' : this.solicitud_id,
                //     'this.subject'      : this.Subject,
                //     // 'this.model'        : this.model,

                //     'this.showDate'     : this.showDate,
                //     'this.date'         : this.date,

                //     'this.showStartTime': this.showStartTime,
                //     'this.StartTime'    : this.StartTime,

                //     'this.showEndTime'  : this.showEndTime,
                //     'this.EndTime'      : this.EndTime,

                //     'this.MUESTREADORES_DB' : this.MUESTREADORES_DB,
                //     'this.location'     : this.direccion,
                //     'this.Description'  : this.Description,
                // }
                                
                const data = {
                    solicitud_id    : this.solicitud_id,
                    Description     : this.Description,
                    EndTime         : `${this.date} ${this.EndTime}:00`, 
                    Muestreador     : this.muestreador.user_id,
                    StartTime       : `${this.date} ${this.StartTime}:00`, 
                    Subject         : this.Subject,
                    id : null,
                    nom_muestreador : this.muestreador.fullname,
                    Location        : this.direccion,
                };

                console.log({data})

                // return;
                
                const response = await Service.apiToken('POST', 'Solicitud/saveEvent', this.$ls.storage.token, data);
                console.log({response})

                // Obtener el id del evento guardado y asignarselo a data.id
                // data.id = response.data.Id

                // La data que acabamos de enviar a guardar a la BD yla transormamos a data tipo event:
                const dataEnFormatoEvent = this.convertToEventsDataSet( [response] )[0]
                
                // La data transformada le hacemos push al arreglo de eventos:
                return;
                this.EVENTOS_DB.push( dataEnFormatoEvent ) 
                console.log({dataEnFormatoEvent})

                // ----------------------------------------------------
                // Eliminar la solicitud del dataset de solicitudes:
                console.log({'this.SOLICITUDES_DB.length':this.SOLICITUDES_DB.length})
                this.SOLICITUDES_DB = this.SOLICITUDES_DB.filter(item => {
                    return item.id != dataEnFormatoEvent.solicitud_id
                })
                console.log({'this.SOLICITUDES_DB.length':this.SOLICITUDES_DB.length})

                // Filtramos de vuelta x los criterios previos (cliente_id)
                this.filterClientesData()
                
                // Cerrar el modal:
                this.showModalEvento = false
            },

            // ---------------------- EDITAR EVENTO:
            editarSolitud(item){
                console.log(item);
                this.banderaEditarSolicitud = true;
                this.selectedOpen = false;
                this.showModalEvento = true;
                this.solicitud_id = item.solicitud_id;

                this.Subject      = item.Subject;
                this.showDate     = false;
                this.date         = item.date;

                this.showStartTime= false;                
                this.StartTime    = this.removerSegundos(item.StartTime);
                

                this.showEndTime  = false;
                this.EndTime      = this.removerSegundos(item.EndTime);

                this.direccion     = item.Location;
                this.Description  = item.Description;
                
                this.muestreadores_filtered = this.MUESTREADORES_DB;
                this.muestreador = item.Muestreador;

                // console.log(this.solicitudes_filtered);

                this.obtenerConteoMuestras(item.preot.muestras_preots);
            },


            // ---------------------- ELIMINAR EVENTO:
            btnEliminarSolicitud(item){
                console.log({item})
                this.selectedOpen = false;
                Swal.fire({
                    title: i18n.t('solicitudes.Advertencia'),
                    text: i18n.t('solicitudes.Deseaeliminarestasolicitud')+' '+item.solicitud_id+'?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: i18n.t('solicitudes.aceptar'),
                    cancelButtonText: i18n.t('solicitudes.cancelar'),
                }).then((result) => {
                    if( result.isConfirmed ){
                        this.eliminarSolicitud(item)
                    }
                });
            },
            async eliminarSolicitud(item){
                const data = {
                    jqcalendar_id : 5,
                    // jqcalendar_id : item.id,
                }
                const response = await Service.apiToken('POST', 'Solicitud/deleteEvent', this.$ls.storage.token, data);
                // console.log({response})

                if( !response.exito ){
                    Swal.fire({
                        title: i18n.t('solicitudes.Success'),
                        text: i18n.t('solicitudes.Eventoeliminadocorrectamente'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    }).then((result) => {
                        this.agregarSolicitud(item)
                        this.eliminarEvento(item)
                    })
                }else{
                    Swal.fire({
                        title: 'FALTA TRADUCIR',//i18n.t('solicitudes.Success'),
                        text: 'ERROR AL ELIMINAR EVENTO',//i18n.t('solicitudes.Eventoeliminadocorrectamente'),
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            },
            eliminarEvento(item){
                console.log({item: item});
                console.log(this.EVENTOS_DB);
                this.EVENTOS_DB = this.EVENTOS_DB.filter( item2 => item2.id != item.id )
                console.log(this.EVENTOS_DB);
            },
            agregarSolicitud(item){
                const tmp_evento = this.EVENTOS_DB.filter( item2 => item2.id == item.id )[0]
                console.log({tmp_evento})
                console.log({'this.EVENTOS_DB' : this.EVENTOS_DB})
                /*this.EVENTOS_DB = [
                    {
                        Description: "B1",
                        EndTime: "07:30:00",
                        Muestreador: 136,
                        StartTime: "07:00:00",
                        Subject: "A1",
                        color: "grey darken-1",
                        date: "2022-09-01",
                        end: 'Thu Sep 01 2022 07:30:00 GMT-0500 (hora de verano central)',
                        id: 57,
                        name: "Alan Sampler 1",
                        solicitud_id: 860,
                        start: 'Thu Sep 01 2022 07:00:00 GMT-0500 (hora de verano central)',
                        timed: true,
                    }
                ]*/

                console.log({'this.SOLICITUDES_DB' : this.SOLICITUDES_DB})
                /*this.SOLICITUDES_DB = [
                    {
                        cliente_id: 84,
                        id: 859,
                        local: {
                            direccion: {
                                cat_pais_id: "3",
                                ciudad: "morelia",
                                codigo_pais: false,
                                codigo_postal: "25000",
                                comuna: "Michoacán",
                                direccion: "av morelos 100",
                                estado_id: "120",
                                nombre_pais: "MX",
                            },
                            direccion_completa: "av morelos 100, Michoacán, morelia, 25000",
                            id: 122,
                            nombre_local: "Pepes",
                        },
                        muestras_preots: [
                            {
                                id_matriz: 12,
                                matriz: "Alimentos",
                            }
                        ]
                    }
                ]*/

                /*
                // FALTA CLIENTE_ID
                // FALTA DIRECCION_COMPLETA
                // FALTA LOCAL
                // FALTA MUESTRAS_PREOTS
                Description: "B1",
                EndTime: "07:30:00",
                Muestreador: 136,
                StartTime: "07:00:00",
                Subject: "A1",
                color: "grey darken-1",
                date: "2022-09-01",
                end: 'Thu Sep 01 2022 07:30:00 GMT-0500 (hora de verano central)',
                id: 57,
                name: "Alan Sampler 1",
                // solicitud_id: 860,
                start: 'Thu Sep 01 2022 07:00:00 GMT-0500 (hora de verano central)',
                timed: true,

                const tmp_solicitud = {
                    // cliente_id: 84,
                    id: tmp_evento.solicitud_id,//859,
                    local: {
                        // direccion: {
                        //     cat_pais_id: "3",
                        //     ciudad: "morelia",
                        //     codigo_pais: false,
                        //     codigo_postal: "25000",
                        //     comuna: "Michoacán",
                        //     direccion: "av morelos 100",
                        //     estado_id: "120",
                        //     nombre_pais: "MX",
                        // },
                        // direccion_completa: "av morelos 100, Michoacán, morelia, 25000",
                        id: 122,
                        nombre_local: "Pepes",
                    },
                    muestras_preots: [
                        {
                            id_matriz: 12,
                            matriz: "Alimentos",
                        }
                    ]
                }
                */
            },
            // ---------------------- Fn ADICIONALES:
            filterClientesData(){
                console.log({'this.SOLICITUDES_DB':this.SOLICITUDES_DB})
                console.log({'this.cliente':this.cliente})

                this.solicitudes_filtered = this.SOLICITUDES_DB.filter( item => 
                    item.cliente_id == this.cliente.id && 
                    item.jqcalendar == null
                )
                console.log({'this.solicitudes_filtered':this.solicitudes_filtered})
            },


            // ---------------------- EVENTOS DE MODALES:
            closeModal(){
                this.showModalEvento = false;

                this.subject      = '';
                this.showDate     = false;
                this.date         = '';

                this.showStartTime= false;
                this.StartTime    = '';

                this.showEndTime  = false;
                this.EndTime      = '';

                this.MUESTREADORES_DB = this.MUESTREADORES_DB;
                this.location     = '';
                this.description  = '';
            },


            // ---------------------- NO MOVER!! - CALENDAR EVENTS:
            viewDay ({ date }) {
                this.focus = date
                this.type = 'day'
            },
            getEventColor (event) {
                return event.color
            },
            setToday () {
                this.focus = ''
            },
            prev () {
                this.$refs.calendar.prev()
            },
            next () {
                this.$refs.calendar.next()
            },
            showEvent ({ nativeEvent, event }) {
                const open = () => {
                    console.log(event);
                    this.selectedEvent = event
                    this.selectedElement = nativeEvent.target
                    requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
                    console.log(this.selectedEvent)
                }

                if (this.selectedOpen) {
                    this.selectedOpen = false
                    requestAnimationFrame(() => requestAnimationFrame(() => open()))
                } else {
                    open()
                }

                nativeEvent.stopPropagation()
            },
            updateRange ({ start, end }) {
                // console.log("updateRange")
                // console.log({start})
                // console.log({end})

                const events = []

                const min = new Date(`${start.date}T00:00:00`)
                // console.log(`${start.date}T00:00:00`)
                // console.log(`${end.date}T23:59:59`)
                const max = new Date(`${end.date}T23:59:59`)
                // console.log({min})
                // console.log({max})

                const days = (max.getTime() - min.getTime()) / 86400000
                const eventCount = 3//this.rnd(days, days + 20)

                for (let i = 0; i < eventCount; i++) {
                    const allDay = this.rnd(0, 3) === 0

                    const firstTimestamp = this.rnd(min.getTime(), max.getTime())
                    // if( i==0 ){ console.log({firstTimestamp}) }
                    // console.log({firstTimestamp})
                    // if( i==0 ){ console.log( firstTimestamp - (firstTimestamp % 900000) ) }
                    // console.log( firstTimestamp - (firstTimestamp % 900000) )

                    const first = new Date(firstTimestamp - (firstTimestamp % 900000))
                    // if( i==0 ){ console.log({first}) }
                    // console.log({first})

                    const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
                    const second = new Date(first.getTime() + secondTimestamp)

                    const random_event = this.rnd(0, this.cat_events.length - 1)

                    events.push({
                        name: this.cat_events[random_event].name,
                        start: first,
                        end: second,
                        color: this.cat_events[random_event].color,
                        
                        timed: !allDay,
                    })
                }

                this.events = events
                console.log({'this.events':this.events}) 
                // console.log("---------------------------------")
            },
            rnd (a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            },
            ocultarbarra() {
                this.leftBar = !this.leftBar;
                console.log( this.leftBar);
            },
            timePickerAllowedStep: m => m % 30 === 0,
            getMes(mes){
                let meses = mes.split(' ');

                if(meses.length == 0){
                    return '--';
                }
                let meslowercase = meses[0].toLowerCase();
                switch (meslowercase) {
                    case 'enero':
                        return i18n.t('meses.enero')+' '+meses[1];
                        break;
                    case 'febreo':
                        return i18n.t('meses.febreo')+' '+meses[1];
                        break;
                    case 'marzo':
                        return i18n.t('meses.marzo')+' '+meses[1];
                        break;
                    case 'abril':
                        return i18n.t('meses.abril')+' '+meses[1];
                        break;
                    case 'mayo':
                        return i18n.t('meses.mayo')+' '+meses[1];
                        break;
                    case 'junio':
                        return i18n.t('meses.junio')+' '+meses[1];
                        break;
                    case 'julio':
                        return i18n.t('meses.julio')+' '+meses[1];
                        break;
                    case 'agosto':
                        return i18n.t('meses.agosto')+' '+meses[1];
                        break;
                    case 'septiembre':
                        return i18n.t('meses.septiembre')+' '+meses[1];
                        break;
                    case 'octubre':
                        return i18n.t('meses.octubre')+' '+meses[1];
                        break;
                    case 'noviembre':
                        return i18n.t('meses.noviembre')+' '+meses[1];
                        break;
                    case 'diciembre':
                        return i18n.t('meses.diciembre')+' '+meses[1];
                        break;
                
                    default:
                        return mes;
                        break;
                }
            },
            calcularhora(hora){
                // console.log('hora',hora);
                const hora2 = parseInt(hora.replace(':',''));
                this.catEndTime = [];
                this.catStartTime.forEach(element =>{
                    let hora1 = parseInt(element.replace(':',''));
                    if(hora1 > hora2){
                        // console.log(hora1);
                        this.catEndTime.push(element);
                    }
                });
                // console.log('this.catStartTime',this.catStartTime)
                // console.log('this.catEndTime',this.catEndTime);
            },
            obtenerConteoMuestras(muestras) {
                this.items.forEach(i => {
                    i.count = 0;
                });

                muestras.forEach(e => {
                    this.items.filter(i => {
                        if (i.text == e.matriz) {
                            i.count++;
                        }
                    })
                });
            },
            removerSegundos(time) {
                let tmp = time.split(':');
                tmp.pop();
                tmp.join(':');

                return tmp[0] + ':' + tmp[1];
            } 
        },
        computed: {
            lang() {
                return i18n.locale;
            },
        },
    }
</script>
